import Page from 'components/Layout/Page'
import { Flex, Box, Text } from 'packages/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import styled from 'styled-components'

const Wrapper = styled(Box).attrs({ maxWidth: '683px', width: '100%', mx: 'auto' })`
  img {
    &.bera-bear {
      display: block;
      margin: 0 auto;
      transform: translate(-15px, 20px);
      z-index: -1;
      position: relative;

      max-width: 137.81px;
      width: 100%;

      ${({ theme }) => theme.mediaQueries.md} {
        max-width: 310px;
        transform: translate(-25px, 55px);
      }
    }
  }
`

const Blob = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  mb: ['26px', , , , '48px'],
})`
  border-radius: 41% 42% 42% 45% / 55% 40% 56% 45%;
  background: white;
  box-shadow: 20px 30px 50px 0px rgba(119, 68, 39, 1);

  max-width: 682.93px;
  width: 100%;
  aspect-ratio: calc(682.93 / 361.43);
  height: auto;
  padding: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 16px 56px;
  }

  img {
    &.title {
      margin: 0 auto;
      max-width: 270.98px;
      width: 100%;
      aspect-ratio: calc(538.05 / 115.22);
      height: auto;
      position: relative;

      ${({ theme }) => theme.mediaQueries.md} {
        max-width: 538.05px;
      }
    }
  }
`

const LaunchHome = () => {
  return (
    <Page py="0px">
      <Wrapper>
        <img className="bera-bear" src="/images/landing-bera.svg" alt="" />
        <Blob>
          <Box mb={['16px', , , , '26px']} mt={['16px', , , , '36px']}>
            <img className="title" src="/images/landing-title.svg" alt="" />
          </Box>

          <Text
            textAlign="center"
            color="var(--primary-color)"
            fontFamily="Righteous"
            fontSize={['12px', , , , '20px']}
          >
            Swap, stake, and earn with Beraswap,
            <br /> the premier DeFi platform tailored for
            <br /> bear lovers and bold investors!
          </Text>
        </Blob>

        <Flex
          mx="auto"
          width={['100%']}
          maxWidth={['240px', , , , 'fit-content']}
          justifyContent="center"
          as={NextLinkFromReactRouter}
          to="/swap"
        >
          <img src="/images/ooga-booga.svg" alt="redirect-nft" />
        </Flex>
      </Wrapper>
    </Page>
  )
}

export default LaunchHome
